'use client';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <h2>Something went wrong!</h2>
        <h2>{`please check :${error.message}`}</h2>
        <button
          onClick={() => reset()}
          className='cursor-pointer rounded-[12px] border border-black p-[10px] dark:border-white dark:bg-black dark:text-white'
        >
          Try again
        </button>
      </body>
    </html>
  );
}
